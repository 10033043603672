import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ScaleLoader } from "react-spinners";
import "./LoadingSeaner.css";

const LoadingSeaner = () => {
  let [color, setColor] = useState("green");

  let [message, setMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setMessage(true);
    }, 1500);
  }, []);
  return (
    <div
      style={{ height: "80vh" }}
      className="container d-flex justify-content-center  align-items-center"
    >
      {message ? <p> Not Found !!</p> : <ScaleLoader color={color} size={250} />}
    </div>
  );
};

export default LoadingSeaner;
