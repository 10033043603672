import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import "./style.css";
import { withRouter } from "react-router-dom";

import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";

import Layout from "../Layout/Layout";

import { BiChevronRight } from "react-icons/bi";
import useSWR from "swr";
import { baseUrl } from "../../App";
import LoadingSeaner from "../LoadingSeaner/LoadingSeaner";
import axios from "axios";

// //Import Breadcrumb
// import Breadcrumbs from "components/Common/Breadcrumb"

const EcommerceProducts = (props) => {
  const [category, setCategory] = useState({
    data: [],
    activeStatus: null,
  });
  const [brand, setBrand] = useState([]);
  const [products, setProducts] = useState([]);
  const [activeAllButton, setActiveAllButton] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const { data, error } = useSWR(`${baseUrl}/products/get`);

  useEffect(() => {
    setProducts(data);
    axios
      .get(`${baseUrl}/category/get`)
      .then((res) => setCategory({ ...category, data: res.data }));
    axios.get(`${baseUrl}/brand/get`).then((res) => setBrand(res.data));
  }, []);

  const getAllProducts = () => {
    setProducts(data);
    setActiveAllButton(true);
    setCategory({ ...category, activeStatus: null });
  };

  const findProductByCategory = (id, index) => {
    setActiveAllButton(false);
    setCategory({ ...category, activeStatus: category.data[index] });

    let filterProducts = data.filter((x) => x.category == id);

    setProducts(filterProducts);
  };

  const findProductByBrand = (e) => {
    let id = e.target.value;
    if (id !== "none") {
      let filterProducts = data.filter((x) => x.brand == id);
      setProducts(filterProducts);
    } else {
      setProducts(data);
    }
  };

  // active category style

  const activeCategory = (index) => {
    if (category.data[index] === category.activeStatus) {
      return "active-category";
    } else {
      return "";
    }
  };

  // active all button

  const activeAllButtonToggle = () => {
    if (activeAllButton) {
      return "active-category";
    } else {
      return "";
    }
  };

  return (
    <Layout>
      <React.Fragment>
        <div className="products-page conatiner">
          <MetaTags>
            <title>Products </title>
          </MetaTags>
          <Container fluid className="pt-5">
            <Row>
              <Col lg="3">
                <Card className="productCatagoryCard">
                  <CardBody>
                    <CardTitle className="mb-4 category-title">
                      Categories
                    </CardTitle>
                    <div>
                      <ul className=" product-category-list">
                        <li
                          className={activeAllButtonToggle()}
                          onClick={() => getAllProducts()}
                        >
                          <BiChevronRight />
                          All Products
                        </li>
                        {category?.data?.lenght === 0
                          ? "Loading...."
                          : category?.data?.map((x, index) => (
                              <li
                                onClick={() =>
                                  findProductByCategory(x.id, index)
                                }
                                key={"_li_" + x.id}
                                className={activeCategory(index)}
                              >
                                <BiChevronRight />
                                {x.name}
                              </li>
                            ))}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg="9">
                <Row className="mb-3">
                  <Col xl="4" sm="6">
                    <div className="mt-2">
                      <h4 className="page-title-product   ">Products </h4>
                    </div>
                  </Col>

                  <Col lg="8" sm="6" className="d-flex justify-content-end">
                    <div
                      style={{ width: "300px" }}
                      className="input-group mb-3"
                    >
                      <select
                        onClick={(e) => findProductByBrand(e)}
                        className="custom-select"
                        id="inputGroupSelect01"
                      >
                        <option selected value="none">
                          Filter by brand{" "}
                        </option>

                        {brand.map((x) => (
                          <option key={x.id} value={x.id}>
                            {x.brandName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="row d-flex justify-content-center">
                    {products?.length === 0 ? (
                      <LoadingSeaner />
                    ) : (
                      (products || data)?.map((x) => (
                        <div className=" home-product-container m-3 p-3 productCatagoryCard">
                          <div className="home-pd-img-container">
                            {" "}
                            <img
                              className="card-img-top"
                              src={x.img}
                              alt=""
                            />{" "}
                          </div>

                          <div className="card-body">
                            <h5 className="card-title text-capitalize text-center pd-name">
                              {x.name}
                            </h5>
                            <small className="card-text text-center">
                              {x.description}
                            </small>
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </Layout>
  );
};

export default withRouter(EcommerceProducts);
