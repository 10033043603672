import axios from "axios";
import React from "react";
import { useState } from "react";
import { useContext } from "react";

import { productContext } from "../index";
import { useForm } from "react-hook-form";
import { BsCloudUpload } from "react-icons/bs";
import Swal from "sweetalert2";
import { baseUrl } from "../../../../App";
const CreateBrand = () => {

   const {setBrandReload}=useContext(productContext)
  const [images, setImages] = useState([]);
  const [Select, setSelect] = useState(false);


  const { register, handleSubmit } = useForm();

  const hendelImageUploaded = (e) => {
    if (e.target.files) {
      setSelect(false);
      if (e.target.files[0].size > 1000000) {
        setImages([]);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File Size too large ",
          footer: "<p> Maximum File Size Allow 1MB</p>",
        });
      } else {
        setImages(e.target.files);
      }
    }
  };



  const onSubmit = (data) => {
    let formData = new FormData();

    if (images.length > 0) {
      setSelect(false);
    
       
        formData.append("image", images[0]);
        formData.append("brandName", data.brandName);
  
        axios
          .post(`${baseUrl}/brand/insert`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.data) {
              setBrandReload(reload=>!reload)
              setImages([]);
              Swal.fire("Good job!", "Added Successfully", "success");
            }
          })
          .catch((err) => {
            setImages([]);
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer:
                "<p> Please check your Image size and Image type and try again</p>",
            });
          });
      
    } else {
      setSelect(true);
    }
  };

  // Get folder name

  return (
    <>
      <div className="card p-4">
        <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
          <div class="form-group mb-3">
            <label for="formGroupExampleInput">Brand Name </label>
            <input
              type="text"
              class="form-control"
              id="formGroupExampleInput"
              placeholder="Brand Name... "
              {...register("brandName", { required: true })}
              required
            />
          </div>

          <div className="mb-3 weight-UPimg">
            <h6>Select photo</h6>

            <input
              style={{ display: "none" }}
              onChange={hendelImageUploaded}
              name="image"
              id="seriesFile"
              type="file"
              accept="image/*"
              multiple={false}
            />

            <label htmlFor="seriesFile">
              <BsCloudUpload /> Upload Photo
            </label>
          </div>
          {Select && <p className="text-danger"> Please Select a image </p>}
          <p style={{ color: "gray" }}>
            {images.length > 0 && "Image Selected "}
          </p>
          <button className="btn btn-outline-primary" type="submit">
            Save
          </button>
        </form>
      </div>
    </>
  );
};

export default CreateBrand;
