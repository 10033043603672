import React from "react";
import "./FetureBrand.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import SwiperCore, { Autoplay,Pagination, Navigation } from "swiper/core";
import useSWR from "swr";
import { baseUrl } from "../../App";
SwiperCore.use([Autoplay,Pagination, Navigation]);
const FetureBrand = () => {


  const { data, error } = useSWR(`${baseUrl}/brand/get`);

  return (
    <div id="fetureBrand" className="container-fluid ">
         <h3 className="text-center p-3 border-bottom-effect">Featured <span className="sub-brand-text"> Brands</span> </h3>
    
            <Swiper
        
        spaceBetween={10}
        navigation={false}
        pagination={false}
        
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          100:{
            slidesPerView: 1,
            spaceBetween: 20,
          },

          300: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          746: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 7,
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 8,
            spaceBetween: 30,
          },
        }}
        className="mySwiper row"
      >
     
        <div className="p-3 brand-images-container ">
          {!data? "loading..." : data.map((x) => (
             <SwiperSlide className="col-2">
            <div className="brand-images-wrapper">
              {" "}
              <img  className="cursor p-3" src={x.logo} alt="" />{" "}
            </div>
            </SwiperSlide>
          ))}
             
         </div>
         </Swiper>
    
    </div>
  );
};

export default FetureBrand;
