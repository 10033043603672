import React from "react";
import "./Footer.css";

import { IoLogoFacebook } from "react-icons/io";
import { useContext } from "react";
import { baseUrl, userContext } from "../../App";
import { useEffect } from "react";
import useSWR from "swr";
import { Link } from "react-router-dom";

const Footer = () => {
 
  const { personalInfo } = useContext(userContext);


  //  details

  return (
    <footer className="footer-area clear-both">
      <div className="container pt-5">
        <div className="row py-5 text-center">
          <div className="col-md-4 mt-md-5">
            <strong className="footer-brand-title">
              {" "}
              A <strong style={{ color: "red" }}>S </strong> EXPORT-IMPORT (S)
              PTE LTD{" "}
            </strong>
                  
                  <p> <a className="footer-fb-icon" href="g">< IoLogoFacebook/> </a> </p>
             
          </div>
          <div className="col-md-4 ">
            <h4 className="text-center ml-3 mb-2 footer-h"> Quick Link </h4>

            <ul id="footer-Menu-items-wrapper" className="">
              <li>
                {" "}
                <Link to="/"> Home</Link>
              </li>
              <li>
                {" "}
                <Link to="/products"> Products</Link>
              </li>
              <li>
                {" "}
                <Link to="/about"> About</Link>
              </li>
              <li>
                {" "}
                <Link to="/contract"> Contact</Link>
              </li>
              <li>
                {" "}
                <Link to="/products"> Brand</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h4 className="text-left mb-2  footer-h"> Contact </h4>
            
           <address className="footer-address text-left">

               <strong style={{fontSize:"16px"}}>Address: </strong>
              {personalInfo?.address}
              
            </address>
       
          

            <p className="text-left">
            <strong style={{fontSize:"16px",color:"#415161"}}> Email: </strong>
             
              <a style={{color:"#415161", textDecoration:"none"}} className="ml-2" href={`mailto:${personalInfo?.email}`}>
                {personalInfo?.email}
              </a>
            </p>
         <p className="text-left">
              <strong style={{fontSize:"16px",color:"#415161"}}>   HP: </strong>
              <a
              style={{color:"#415161"}} 
                className="footer-phoneNumber ml-2"
                href={`tel:${personalInfo?.phoneNumber}`}
              >
                {" "}
                {personalInfo?.phoneNumber}
              </a>
            </p>
          </div>
        </div>
<hr/>
        <div className="col-md-12 d-flex justify-content-center">
          <div className="copy-right-text">
            <p>
              {" "}
              Copyright @ {new Date().getFullYear()} All rights reserved by <small className="mx-3 text-danger">A S EXPORT-IMPORT (S) PTE LTD</small>
              Developed by{" "}
              <a href="https://www.mensadigiworld.com">Mensa Digiworld</a>{" "}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
