import React, { useContext, useEffect, useState } from "react";
import "./About.css";
import { baseUrl } from "../../App";
import { userContext } from "../../App";

import Layout from "../Layout/Layout";
import useSWR from "swr";

const About = () => {
  const { personalInfo } = useContext(userContext);

  const { data, error } = useSWR(`${baseUrl}/about/get`);

  return (
    <>
      <Layout>
        <div className=" about-container relative">
          <div className="background-about-overlay">
            <div className="container content-wrapper ">
              <div className="row  d-flex justify-content-center align-content-center">
                <h1 className="text-center background-overlay-heading">
                  About US
                </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-4 about-details-container">
          <div className="row">
            <div className="col-12">
              <h1 className="text-left ">About</h1>
            </div>
            <div className="col-10 offset-md-1 mt-4">
              {!data
                ? "Loading...."
                : data.map((x) => (
                    <p className="text-md-left  mb-4">
                     {x.details}
                    </p>
                  ))}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default About;
