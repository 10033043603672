import React from "react";
import useSWR from "swr";
import { baseUrl } from "../../App";

const Services = () => {
  const { data, error } = useSWR(`${baseUrl}/displayImages/get`);
  if (!data) {
    return "Loading....";
  }

  return (
    <div className="container my-5 ">
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <div className="col-12 order-md-1 order-2 p-3">
              <div className="services-image-container">
                <img src={data[0].image} alt="..." />
              </div>
            </div>
            <div className="col-md-12 order-md-2 order-1 p-3 service-heading-container"></div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12 p-3 service-heading-container"></div>
            <div className="col-12 p-3 mt-md-5">
              <div className="services-image-container">
                <img src={data[1].image} alt="..." />
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="row">
            <div className="col-12 order-md-1 order-2 p-3">
              <div className="services-image-container">
                <img src={data[2].image} alt="..." />
              </div>
            </div>
            <div className="col-md-12 order-md-2 order-1 p-3 service-heading-container"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
