import React from 'react';
import { useState } from 'react';
import PanelHeader from '../../components/PanelHeader/PanelHeader';
import SlideConfig from './SlideConfig';
import styles from "./Slider.module.css"
import SliderImages from './SliderImages';

const Slider = () => {
    const [ImgReload,setImgReload]=useState(false)
    return (
        <>
             <PanelHeader
        size="sm"
        content={
          <div className="header text-center">
            <h2 className="title">Slide Setting</h2>
          </div>
        }
      />
        <div className="content">
        <div className="row">
          <div className="col-md-8 offset-md-2 mb-4">
             
          <SliderImages setImgReload={setImgReload} />
          </div>


          <div className="col-md-8 offset-md-2">
              <h2 className={`${styles.heading} brand-text`}>Slider Configuration </h2>
          
          </div>
          <div className="col-md-10 offset-md-2">
          <div className="row">
              <SlideConfig ImgReload={ImgReload} setImgReload={setImgReload}/>
              </div>
             
          
          </div>
        </div>
       
      </div>
        </>
    );
};

export default Slider;