import React from "react";
import { useHistory } from "react-router";
import img1 from "../../resource/backgroundImge/1.jpg";

const BackgroundOverlay = ({ bgImage, height, content, title }) => {
  const history = useHistory();

  const background_attachment_Image = {
    backgroundAttachment: "fixed",
    backgroundColor: "#FFFFFF",
    height: height,
    backgroundImage: `url(${bgImage})`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
  };
  const background_color_overlay = {
    height: "100%",
    width: "100%",
    top: "0",
    left: " 0",
    backgroundColor: "rgb(128 62 62 / 71%)",
    position: "absolute",
  };

  const content_container = {
    backgroundColor: "#00000080",
  };
  const Height = {
    height: "100%",
  };
  const Heading = {
    color: "white",
    wordSpacing: "2px",
  };

  const backgroundOverlayHeading={

    opacity:"1",
    zIndex:"111111111111",
    textTransform: "uppercase",
    color:"white",
    fontFamily: `var(--heading)`,
    letterSpacing: "10px",
  }
  return (
    <div style={background_attachment_Image} className="my-4">
      <div style={background_color_overlay} className="p-3">
        <div style={Height} className="container ">
          {content ? (
            <>
              <div style={Height} className="row  d-flex  align-content-center">
                <div className="col-md-6">
                  <div
                    style={content_container}
                    className="content_container p-3 card border-0"
                  >
                    <div className="card-body">
                      <h3
                        style={Heading}
                        className="text-center text-uppercase heading"
                      >
                        We ensure 100% Best quality product{" "}
                      </h3>
                      <div className="p-3 d-flex justify-content-center">
                        <button
                          onClick={() => history.push("/products")}
                          className="btn btn-outline-primary mt-2"
                        >
                          Shop Now{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div style={Height} className="row  d-flex justify-content-center align-content-center">
              <h1 style={backgroundOverlayHeading} className="text-center">{title}</h1>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BackgroundOverlay;
