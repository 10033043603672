import Dashboard from "./views/Deshboard/Dashboard";
import About from "./views/About/About";
import Products from "./views/Projects";

// React icon
import { IoQrCodeSharp } from "react-icons/io5";
import { FcAbout } from "react-icons/fc";
import { AiFillProject } from "react-icons/ai";
import { GrSettingsOption } from "react-icons/gr";

import Slider from "./views/Slider/Slider";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: <IoQrCodeSharp />,
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/About",
    name: "About",
    icon: <FcAbout />,
    component: About,
    layout: "/admin",
  },

  {
    path: "/products",
    name: "Products",
    icon: <AiFillProject />,
    component: Products,
    layout: "/admin",
  },

  {
    path: "/SliderSetting",
    name: "Slider  Setting",
    icon: <GrSettingsOption />,
    component: Slider,
    layout: "/admin",
  },
];
export default dashRoutes;
