import React from "react";

import { Container, Nav, Navbar } from "react-bootstrap";
import styles from "./Navigation.module.css";
import { BiDotsHorizontal } from "react-icons/bi";
import { BiAlignLeft } from "react-icons/bi";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navigation = ({ fixed }) => {
  const [toggleButton, settoggleButton] = useState(true);
  const isActive = {
    borderBottom: "4px solid white",
    color: "#ff5151",
  };

  return (
    <Navbar expand="lg" fixed={fixed && "top"}>
      <Container>
        <Navbar.Brand href="/home">
          {/* <img className="w-75" src={logo} alt="logo" /> */}
          <strong>
            A <strong style={{ color: "red" }}>S </strong> EXPORT-IMPORT (S) PTE
            LTD
          </strong>
        </Navbar.Brand>
        <Navbar.Toggle onClick={() => settoggleButton(!toggleButton)}>
          {toggleButton ? (
            <span className={styles.show_icon}>
              <BiAlignLeft />
            </span>
          ) : (
            <span className={styles.show_icon}>
              <BiDotsHorizontal />
            </span>
          )}
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link exact activeStyle={isActive} as={NavLink} to="/">
              Home
            </Nav.Link>

            <Nav.Link activeStyle={isActive} as={NavLink} to="/products">
              Product
            </Nav.Link>

            <Nav.Link activeStyle={isActive} as={NavLink} to="/about">
              About
            </Nav.Link>

            <Nav.Link activeStyle={isActive} as={NavLink} to="/contract">
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
