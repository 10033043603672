import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";

import PanelHeader from "../../components/PanelHeader/PanelHeader";

import "./style.css";
import Brand from "./Brand/Brand";
import Category from "./Category/Category";
import ProductUpload from "./ProductUpload";
import { createContext } from "react";
import { baseUrl } from "../../../App";

export const productContext = createContext();
const Products = () => {
  const [category, setCetagory] = useState([]);
  const [brand, setBrand] = useState([]);
  const [categoryReload, setCategoryReload] = useState(false);
  const [brandReload, setBrandReload] = useState(false);

  useEffect(() => {

    axios(`${baseUrl}/category/get`)
    .then(res=>setCetagory(res.data))
   
   }, [categoryReload])


   useEffect(() => {
    axios.get(`${baseUrl}/brand/get`).then((res) => {
      setBrand(res.data);
    });
  }, [brandReload]);
  return (
    <>
      <PanelHeader
        size="sm"
        content={
          <div className="header text-center">
            <h2 className="title text-capitalize">Products</h2>
          </div>
        }
      />
      <div className="content">
        <div className="row">
          <productContext.Provider
            value={{ category, brand, setCategoryReload, setBrandReload }}
          >
            <Brand />
            <Category />
            <ProductUpload />
          </productContext.Provider>
        </div>
      </div>
    </>
  );
};

export default Products;
