import React from "react";
import "./Product.css";
import useSWR from "swr";
import { baseUrl } from "../../../App";
import LoadingSeaner from "../../LoadingSeaner/LoadingSeaner";

const Product = () => {
  const { data, error } = useSWR(`${baseUrl}/products/get`);

 

  return (
    <div className="container-fluid">
      <h3 className="home-product-heading">Products</h3>
      <div className="row d-flex justify-content-center">
        { !data?  <LoadingSeaner />:  data.map((x) => (
          <div className=" home-product-container m-3 p-3 ">
            <div className="home-pd-img-container">
              {" "}
              <img className="card-img-top" src={x.img} alt="" />
            </div>

            <div className="card-body">
              <h5 className="card-title text-capitalize text-center pd-name">
                {x.name}
              </h5>
              <small className="card-text text-capitalize d-block text-center">
                {x.description}
              </small>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
