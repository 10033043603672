import React, { useContext } from "react";
import "./Dashboard.css";
import PanelHeader from "../../components/PanelHeader/PanelHeader.js";
import CreateAdminAccount from "./CreateAdminAccount";
import DeleteAdminAccess from "./DeleteAdminAccess";
import { createContext } from "react";
import { useState } from "react";
import { userContext } from "../../../App";
import DisplayImage from "./DisplayImage";

export const loadingContext=createContext()
function Dashboard() {

const [adminReload, setAdminReload]=useState(false)
const {personalInfo}=useContext(userContext)


  return (
    <loadingContext.Provider value={{adminReload, setAdminReload}}>
      <PanelHeader
        size="sm"
        content={
          <div className="header text-center">
            <h2 className="title">Dashboard</h2>
          </div>
        }
      />
      <div className="content">
        <div className="row">
          <div className="col-md-12">
            <div className="card p-3">
              <h3 className=" text-center brand-text">
                {" "}{personalInfo?.name} 
              </h3>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="card p-4 my-4">
              <CreateAdminAccount />
            </div>
          </div>

          <div className="col-md-6">
            <div className="card p-4 my-4">
              <DeleteAdminAccess />
            </div>
          </div>

          <div className="col-md-12">
             <h3 className="text-center shadow p-4 m-4">Display Images Setting</h3>
          </div>

          <div className="col-md-12 d-flex justify-content-center flex-wrap mb-5 pb-5">
               
          <DisplayImage/>
                
          </div>
        </div>
      </div>

    </loadingContext.Provider>
  );
}

export default Dashboard;
