import React from "react";

import Toggle from "react-toggle";
import "react-toggle/style.css";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import SubmitButton from "../SubmitButton/SubmitButton";
import DescriptionSetting from "./DescriptionSetting"

const AboutForm = () => {
  const baseUrl = process.env.REACT_APP_API_BASEURL;
  const [reload,setReload]=useState(false)
  const { register, handleSubmit } = useForm();

  const [description, setDescription] = useState("");

  const onSubmit = (data) => {
    axios
      .post(`${baseUrl}/about/insert`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        Swal.fire("Good job!", "Added Successfully", "success");
        setReload(reload=>!reload)
        setDescription("");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<p className="text-danger">Please Try again</p>',
        });
        setDescription("");
      });
  };

  return (
    <>
      <div className="card p-4">
        <h3 className="brand-text  text-center">About </h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-3">
            <label for="exampleFormControlTextarea1" className="form-label">
              About Description
            </label>
            <textarea
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
              {...register("description", { required: true, minLength: 10 })}
              onChange={(e) => setDescription(e.target.value)}
              value={description}
              required
            ></textarea>
          </div>
          <SubmitButton text="Save" />
        </form>
      </div>

      <DescriptionSetting reload={reload}setReload={setReload}/>
    </>
  );
};

export default AboutForm;
