import React from "react";
import "./About.css";
import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import Swal from "sweetalert2";
import SubmitButton from "../SubmitButton/SubmitButton";
import { useEffect } from "react";
import { baseUrl } from "../../../App";


const AboutPersonalDataForm = () => {
  const [contractInformation, setContractInformation] = useState({});

  const [reload, setReload] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // update information
  const onSubmit = (data) => {
    if (data.name === "") {
      data.name = contractInformation.name;
    }

    if (data.email === "") {
      data.email = contractInformation.email;
    }
    if (data.phoneNumber === "") {
      data.phoneNumber = contractInformation.phoneNumber;
    }
    if (data.address === "") {
      data.whatsAppNumber = contractInformation.address;
    }
    if (data.facebook === "") {
      data.facebook = contractInformation.facebook;
    }

    axios
      .patch(
        `${baseUrl}/adminInformation/update/${contractInformation.id}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (res) {
          setReload(!reload);
          Swal.fire("Good job!", " Your Information Update", "success");
        }
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: '<p className="text-danger">Please Try again</p>',
        });
      });
  };

  // get personal information data
  useEffect(() => {
    axios
      .get(`${baseUrl}/adminInformation/get`)
      .then((result) => setContractInformation(result.data[0]));
  }, [reload]);

  return (
    <div className="card p-4 mt-4">
      <h3 className="brand-text  text-center">Personal Information </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-3">
          <label className="form-label" for="exampleFormControlInput1">
            Name :
          </label>

          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="Your Name "
            {...register("name", { required: false })}
            defaultValue={contractInformation?.name}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" for="exampleFormControlInput2">
            Email :
          </label>

          <input
            type="email"
            className="form-control"
            id="exampleFormControlInput2"
            placeholder="Your Email  "
            {...register("email", { required: false })}
            defaultValue={contractInformation?.email}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" for="exampleFormControlInput3">
            Phone Number :
          </label>

          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput3"
            placeholder="Your Phone Number "
            {...register("phoneNumber", { required: false })}
            defaultValue={contractInformation?.phoneNumber}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" for="exampleFormControlInput4">
            Address:
          </label>

          <input
            type="text"
            className="form-control"
            id="exampleFormControlInput4"
            placeholder="Address "
            {...register("address", { required: false })}
            defaultValue={contractInformation?.address}
          />
        </div>
        <div className="mb-3">
          <label className="form-label" for="exampleFormControlInput5">
            Facebook Link :
          </label>

          <input
            type="url"
            className="form-control"
            id="exampleFormControlInput5"
            placeholder="Put your facebook link "
            {...register("facebook", {
              required: false,
            })}
            defaultValue={contractInformation?.facebook}
          />
          {errors.facebook && (
            <span className="text-danger">Enter valide URl</span>
          )}
        </div>

        <SubmitButton text="Update" />
      </form>
    </div>
  );
};

export default AboutPersonalDataForm;
// icon={MdSystemUpdateAlt}
