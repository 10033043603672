import axios from "axios";
import React from "react";
import "./Brand.css"
import { useEffect } from "react";
import { useState } from "react";
import BrandSetting from "./BrandSetting";


import CreateBrand from "./CreateBrand";
import { baseUrl } from "../../../../App";
const Brand = () => {
 



  return (
    <>
      <div className="col-md-10   offset-md-1">
        <h4 className="text-gray text-center shadow p-3 text-capitalize">
        Create Brand 
        </h4>
        <CreateBrand  />
      </div>
      <div className="col-md-10 offset-md-1 my-3">
        <h4 className="text-gray text-center shadow p-3">Brand Setting </h4>
               <BrandSetting />
      </div>
    </>
  );
};

export default Brand;
