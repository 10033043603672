import React from "react";

import Navigation from "../Navigation/Navigation";
import Footer from "../Footer/Footer.jsx";

const Layout = ({ children }) => {
  return (
    <>
      <Navigation fixed={false}/>
      <div  className="bodyContainerWrapper"> 
      {children}
      </div>
     
      <Footer />
    </>
  );
};

export default Layout;
