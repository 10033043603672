import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";

const DisplayImage = () => {
  const [imgData, setimgData] = useState([]);
  const [reload, setReload] = useState(false);
  const baseUrl = process.env.REACT_APP_API_BASEURL;

  // image update heandelar

  //   get slider images

  useEffect(() => {
    axios
      .get(`${baseUrl}/displayImages/get`)
      .then((data) => setimgData(data.data));
  }, [reload]);

  const hendelImagesUpdate = async (id, img) => {
    let formData = new FormData();
    // find previous image name
    const index = img.lastIndexOf("/") + 1;
    const imageName = img.substr(index);

    // input images by popup
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
      },
    });

    if (file?.size > 1000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File Size too large ",
        footer: "<p> Maximum File Size Allow 1MB</p>",
      });
    } else {
      formData.append("image", file);
      formData.append("id", id);
      formData.append("preImgUrl", imageName);
      axios
        .patch(`${baseUrl}/displayImages/update`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const reader = new FileReader();
            reader.onload = (e) => {
              Swal.fire({
                title: "Your uploaded picture",
                imageUrl: e.target.result,
                imageAlt: "The uploaded picture",
              });
            };
            reader.readAsDataURL(file);

            setReload((imgReload) => !imgReload);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Uploaded failed",
            footer: "<p> Server error Try again later!!</p>",
          });
        });
    }
  };

  return (
    <>
      {imgData.length > 0 &&
        imgData.map((imgData) => (
          <>
            <div className="display-images-container m-3">
              <div className="card shadow p-2   ">
                <img className="text-center " src={imgData?.image} alt="" />

                <button
                  onClick={() => hendelImagesUpdate(imgData.id, imgData.image)}
                  className="btn btn-outline-success mb-2 mt-1"
                >
                  Update
                </button>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default DisplayImage;
