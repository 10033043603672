import React from "react";
import BackgroundOverlay from "../BackgroundOverlay/BackgroundOverlay";
import Layout from "../Layout/Layout";

import "./Contract.css";
import ContractForm from "./ContractForm";
import ContractInfo from "./ContractInfo";
import contactImg from "../../resource/img/Contact.jpg";

const Contract = () => {
  return (
    <Layout>
      <BackgroundOverlay
        bgImage={contactImg}
        height={200}
        content={false}
        title="Contact US"
      />

      <div id="contract">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <ContractInfo />
            </div>
            <div className="col-lg-10 offset-lg-1">
              <ContractForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Contract;
