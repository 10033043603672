import "bootstrap/dist/css/bootstrap.css";
import "./adminDeshbord/assets/css/demo.css";
import "./adminDeshbord/assets/scss/now-ui-dashboard.scss";
import "./App.css";
import "./resource/GlobalStyle/globalColor.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./components/Home/Home";

import { useEffect, useState } from "react";
import Contract from "./components/Contract/Contract";
import About from "./components/About/About";
import AdminLayout from "./adminDeshbord/layouts/Admin";
import LogIn from "./adminDeshbord/LogIn/LogIn";
import { createContext } from "react";
import PrivateRoute from "./adminDeshbord/LogIn/PrivateRoute";
import axios from "axios";
import EcommerceProducts from "./components/EcommerceProducts";
import { SWRConfig } from "swr";

export const userContext = createContext();
export const baseUrl = process.env.REACT_APP_API_BASEURL;

function App() {
  const [loginUser, setLoginUser] = useState({});
  const [personalInfo, setPersonalInfo] = useState({});

 
  // get projectData from database
  useEffect(() => {
    axios
      .get(`${baseUrl}/adminInformation/get`)
      .then((result) => {
        setPersonalInfo(result.data[0]);
      })
      .catch(err=>{

         console.log(err.message)
      })
  }, []);

  return (
    <userContext.Provider value={{ loginUser, setLoginUser, personalInfo }}>
      <SWRConfig
        value={{
          refreshInterval: 3000,
          fetcher: (url) => axios.get(url).then((res) => res.data),
        }}
      >
        <Router>
          <Switch>
            <Route path="/" exact component={Home} />

            <Route exact path="/products">
              <EcommerceProducts />
            </Route>

            <Route exact path="/about">
              <About />
            </Route>

            <Route exact path="/contract">
              <Contract />
            </Route>
            <Route exact path="/login">
              <LogIn />
            </Route>

            <PrivateRoute path="/admin">
              <Route render={(props) => <AdminLayout {...props} />} />
            </PrivateRoute>
          </Switch>
        </Router>
      </SWRConfig>
    </userContext.Provider>
  );
}

export default App;
