import axios from "axios";
import React from "react";
import { useRef } from "react";

import { useContext } from "react";

import Swal from "sweetalert2";
import { productContext } from "../index";
import { baseUrl } from "../../../../App";
import { Toast } from "../../Deshboard/Notification";
import CategorySetting from "./CategorySetting";

const Category = () => {

 const {setCategoryReload}=useContext(productContext)
  const FolderRef = useRef("");

  const createCategory = (e) => {
    e.preventDefault();
    const name = FolderRef.current.value;

    axios
      .post(
        `${baseUrl}/category/insert`,
        { name },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {

        setCategoryReload(reload=>!reload)
        FolderRef.current.value = "";
        Toast.fire({
          icon: "success",
          title: "Category Create  successfully",
        });
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `<p  href="">Please try again!!!!</p>`,
        });
      });
  };


 


  return (
    <>
      <div className="col-md-8 offset-md-2 mb-4">
        <h4 className="text-gray text-center shadow p-3 text-capitalize">
          Create Category
        </h4>
        <div className="card shadow p-4">
          <form onSubmit={createCategory}>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                placeholder="Enter Category Name"
                aria-label="Recipient's username"
                aria-describedby="button-addon2"
                ref={FolderRef}
                required
              />
              <button class="btn btn-success" type="submit" id="button-addon2">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
      <div className="col-md-10 offset-md-1 my-3">
        <h4 className="text-gray text-center shadow p-3">Category Setting </h4>
        

        <CategorySetting  />
      </div>
    </>
  );
};

export default Category;
