import axios from "axios";
import React from "react";
import { Table } from "react-bootstrap";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { MdCloudUpload } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { baseUrl } from "../../../../App";
import { productContext } from "../index";
import { Toast } from "../../Deshboard/Notification";
import { useContext } from "react";

const BrandSetting = () => {

  
  const {brand,setBrandReload}=useContext(productContext)
  const handelEdit = async (id) => {
    const { value: brandName } = await Swal.fire({
      title: "Enter Brand Updated  Name",
      input: "text",
      inputLabel: "Brand Name",
      inputPlaceholder: "Enter Brand Name",
    });

    if (brandName) {
      const data = { id, brandName };

      axios
        .patch(`${baseUrl}/brand/name/update`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setBrandReload((reload) => !reload);
          Toast.fire({
            icon: "success",
            title: "Updated  successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `<p  href="">Please try again!!!!</p>`,
          });
        });
    }
  };

  // delete
  const hendelDelete = (id, logo) => {
    const index = logo.lastIndexOf("/") + 1;
    const imageName = logo.substr(index);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/brand/delete/${id}/${imageName}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            setBrandReload((reload) => !reload);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `<p  href="">Please try again!!!!</p>`,
            });
          });
      }
    });
  };

  // update brand images

  const updateBrandImage = async (id, img) => {
    let formData = new FormData();
    // find previous image name
    const index = img.lastIndexOf("/") + 1;
    const imageName = img.substr(index);

    // input images by popup
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
      },
    });

    if (file?.size > 1000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File Size too large ",
        footer: "<p> Maximum File Size Allow 1MB</p>",
      });
    } else {
      formData.append("image", file);
      formData.append("id", id);
      formData.append("imageName", imageName);
      axios
        .patch(`${baseUrl}/brand/image/update`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const reader = new FileReader();
            reader.onload = (e) => {
              Swal.fire({
                title: "Your uploaded picture",
                imageUrl: e.target.result,
                imageAlt: "The uploaded picture",
              });
            };
            reader.readAsDataURL(file);

            setBrandReload((reload) => !reload);
          }
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Uploaded failed",
            footer: "<p> Server error Try again later!!</p>",
          });
        });
    }
  };

  return (
    <>
      <div className="card p-4 shadow my-3">
        <Table striped bordered hover size="sm">
          <thead>
            <tr className="table-heading-data">
              <th className="text-center">SL</th>
              <th className="text-center">Brand Name</th>
              <th className="text-center">Action</th>
              <th className="text-center">Brand Logo</th>
              <th colspan="2"className="text-center">Action</th>
             
            </tr>
          </thead>
          <tbody className="">
            {brand.map((x, index) => {
              return (
                <tr key={x.id}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-center">{x.brandName}</td>
                  <td className="text-center">
                    <span
                      onClick={() => handelEdit(x.id)}
                      className="cursor-pointer"
                    >
                      <FiEdit className="text-success" />
                    </span>
                  </td>
                  <td className="text-center">
                    <img
                      style={{ width: "100px", height: "50px" }}
                      src={x.logo}
                      alt=""
                    />
                  </td>
                  <td className="text-center">
                    <span
                      onClick={() => updateBrandImage(x.id, x.logo)}
                      className="cursor-pointer"
                    >
                      <MdCloudUpload className="text-success" />
                    </span>
                  </td>
                  <td className="text-center">
                    <span onClick={() => hendelDelete(x.id, x.logo)}>
                      <RiDeleteBin6Line className="text-danger cursor-pointer" />
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default BrandSetting;
