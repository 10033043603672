import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Swal from "sweetalert2";

const SlideConfig = ({ImgReload,setImgReload}) => {
  const [imgData, setimgData] = useState([]);
  const baseUrl=process.env.REACT_APP_API_BASEURL

   // image update heandelar
 

//   get slider images 

  useEffect(() => {
    axios
      .get(`${baseUrl}/slider/get`)
      .then((data) => {
        setimgData(data.data);
      })
      .catch((err) => {
        console.log(err);
      });

  
  }, [ImgReload]);

  const hendelImagesUpdate = async (id,img) => {
    let formData = new FormData();
    // find previous image name
    const  index = img.lastIndexOf("/") + 1;
    const  imageName = img.substr(index);

    // input images by popup
    const { value: file } = await Swal.fire({
      title: "Select image",
      input: "file",
      inputAttributes: {
        accept: "image/*",
        "aria-label": "Upload your profile picture",
      },
    });

    if (file?.size > 1000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File Size too large ",
        footer: "<p> Maximum File Size Allow 1MB</p>",
      });
    } else {
      formData.append("image", file);
      formData.append("id", id);
      formData.append("PreImgUrl", imageName)
      axios
        .patch(`${baseUrl}/slider/image/update`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          if (response.data) {
            const reader = new FileReader();
            reader.onload = (e) => {
              Swal.fire({
                title: "Your uploaded picture",
                imageUrl: e.target.result,
                imageAlt: "The uploaded picture",
              });
            };
            reader.readAsDataURL(file);

            setImgReload((imgReload) => !imgReload);
          }
        })
        .catch((err) => {

          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Uploaded failed",
            footer: "<p> Server error Try again later!!</p>",
          });
        });
    }
  };

  //    images delete hendelar
  const hendelImagesDelete = (id,image) => {

    // find image 
    const  index = image.lastIndexOf("/") + 1;
    const  imageName = image.substr(index);
  
  
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/slider/image/delete/${id}/${imageName}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            setImgReload((imgReload) => !imgReload);
          })
          .catch((err) => {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'Something went wrong!',
              footer: `<p  href="">Please try again!!!!</p>`
            })
          });
      }
    });
  };



  return (
    <>
      {imgData.length > 0 &&
        imgData.map((imgData) => (
          <>
            <div className="col-xl-2 col-lg-3  col-md-4 col-6 ">
              <div className="card shadow-lg p-4 mb-3 admin-project-images-container">
                <img src={imgData?.image} alt="" />

                <button
                  onClick={() => hendelImagesUpdate(imgData.id,imgData.image)}
                  className="btn btn-outline-success mb-2 mt-1"
                >
                  Update
                </button>
                <button
                  onClick={() => hendelImagesDelete(imgData.id,imgData.image)}
                  className="btn btn-danger"
                >
                  Delete
                </button>
              </div>
            </div>
          </>
        ))}
    </>
  );
};

export default SlideConfig;
