import axios from "axios";
import React from "react";
import { useEffect } from "react";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { BsCloudUpload } from "react-icons/bs";
import Swal from "sweetalert2";
import { baseUrl } from "../../../App";
import ProductDelete from "./ProductDelete";
import { productContext } from "./index";
import { useContext } from "react";
const ProductUpload = () => {
  const { brand, category } = useContext(productContext);
  const [images, setImages] = useState([]);
  const [Select, setSelect] = useState(false);
  const [productReload, setProductReload] = useState(false);

  const [imgFolder, setImgFolder] = useState(true);

  const { register, handleSubmit, reset } = useForm();

  const hendelImageUploaded = (e) => {
    if (e.target.files) {
      setSelect(false);
      if (e.target.files[0].size > 1000000) {
        setImages([]);

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "File Size too large ",
          footer: "<p> Maximum File Size Allow 1MB</p>",
        });
      } else {
        setImages(e.target.files);
      }
    }
  };

  const hendelFolder = (e) => {
    if (images.length > 0) {
      if (e.target.value !== "false") {
        setImgFolder(true);
      } else {
        setImgFolder(false);
      }
    }
  };

  const onSubmit = (data) => {
    let formData = new FormData();

    if (images.length > 0) {
      setSelect(false);
      if (data.brand === "false" || data.category === "false") {
        setImgFolder(false);
      } else {
        setImgFolder(true);
        formData.append("image", images[0]);
        formData.append("brand", data.brand);
        formData.append("category", data.category);
        formData.append("productName", data.productName);
        formData.append("productDescription", data.productDescription);
        axios
          .post(`${baseUrl}/products/insert`, formData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            if (response.data) {
              reset();
              setImages([]);
              Swal.fire("Good job!", "Added Successfully", "success");
              setProductReload((pdReload) => !pdReload);
            }
          })
          .catch((err) => {
            setImages([]);
            reset();
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer:
                "<p> Please check your Image size and Image type and try again</p>",
            });
          });
      }
    } else {
      setSelect(true);
    }
  };
  const [product, setProduct] = useState([]);

  useEffect(() => {
    axios.get(`${baseUrl}/products/get`).then((res) => setProduct(res.data));
  }, [productReload]);

  // hendel Product delete
  const hendelDeleteProduct = (id, img) => {
    const index = img.lastIndexOf("/") + 1;
    const imageName = img.substr(index);

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/products/delete/${id}/${imageName}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", "Product has been deleted.", "success");
            setProductReload((pdReload) => !pdReload);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `<p  href="">Please try again!!!!</p>`,
            });
          });
      }
    });
  };
  return (
    <>
      <div className="col-md-10 offset-md-1">
        <h4 className="text-gray text-center shadow p-3">Products Uploaded</h4>
        <div className="card p-4">
          {!imgFolder && (
            <p className="text-danger"> Please Select Brand and category</p>
          )}
          <form onSubmit={handleSubmit(onSubmit)} enctype="multipart/form-data">
            <div className="form-row">
              <div className="mb-3 form-group col-md-6">
                <select
                  className="form-select"
                  {...register("category", { required: true })}
                  aria-label="Default select example"
                  required
                  onChange={hendelFolder}
                >
                  <option value="false" selected>
                    Select Category
                  </option>
                  {category.map((x) => (
                    <option value={x.id}>{x.name}</option>
                  ))}
                </select>
              </div>
              <div className="mb-3 form-group col-md-6">
                <select
                  className="form-select"
                  {...register("brand", { required: true })}
                  aria-label="Default select example"
                  required
                  onChange={hendelFolder}
                >
                  <option value="false" selected>
                    Select Brand
                  </option>
                  {brand.map((x) => (
                    <option value={x.id}>{x.brandName}</option>
                  ))}
                </select>
              </div>
              <div class="form-group col-md-6 p-3">
                <label for="productName">Product Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="productName"
                  placeholder="Product Name..."
                  {...register("productName", { required: true })}
                  required
                />
              </div>
              <div class="form-group col-md-6 p-3">
                <label for="productDescription">Product Description</label>
                <input
                  type="text"
                  className="form-control"
                  id="productDescription"
                  placeholder="Product Description..."
                  {...register("productDescription", { required: true })}
                  required
                />
              </div>
              <div className="mb-3 weight-UPimg">
                <h6>Select a photo</h6>

                <input
                  style={{ display: "none" }}
                  onChange={hendelImageUploaded}
                  name="image"
                  id="file"
                  type="file"
                  accept="image/*"
                  multiple={false}
                />

                <label htmlFor="file">
                  <BsCloudUpload /> Upload Photo
                </label>
              </div>
              {Select && (
                <p className="text-danger"> Please Select minimum One image </p>
              )}
              <p style={{ color: "gray" }}>
                {images.length > 0 && images.length + "  " + "Image Selected "}
              </p>

              <div className="form-group col-12 p-3">
                <button className="btn btn-outline-success" type="submit">
                  Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-md-10 offset-md-1">
        <h4 className="text-gray text-center shadow p-3 ">Products Setting</h4>
      </div>
      <div className="col-md-10 offset-md-1 d-flex justify-content-center flex-wrap">
        <ProductDelete
          product={product}
          hendelDeleteProduct={hendelDeleteProduct}
        />
      </div>
    </>
  );
};

export default ProductUpload;
