import React from "react";

const ProductDelete = ({ product,hendelDeleteProduct }) => {
  return (
    <>
      {product.map((x) => (
        <div
          className="card p-4 shadow m-3 product-delete-card-container
         d-flex justify-content-center "
        >
          {" "}
          <div className="card-body">
            <img src={x.img} alt="" />
            <p className="text-center text-uppercase">{x.name}</p>
          </div>{" "}
          <button onClick={()=>hendelDeleteProduct(x.id,x.img)} className="btn btn-danger ">Delete </button>
        </div>
      ))}
    </>
  );
};

export default ProductDelete;
