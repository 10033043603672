import React from "react";
import { baseUrl,  } from "../../App";
import Layout from "../Layout/Layout";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "./Home.css";

import bgImageHome from "../../resource/backgroundImge/1.jpg";

import LoadingSeaner from "../LoadingSeaner/LoadingSeaner";

import FetureBrand from "../FetureBrand/FetureBrand";
import Services from "./Services";
import Product from "./Product/Product";
import BackgroundOverlay from "../BackgroundOverlay/BackgroundOverlay";

import ContractForm from "../Contract/ContractForm";
import useSWR from "swr";

const Home = () => {


  const { data, error } = useSWR(`${baseUrl}/slider/get`);


  return (
    <>
      <Layout>
        <div className="slider-container">
          <div class="ocean">
            <div class="wave"></div>
            <div class="wave"></div>
          </div>
          <Carousel
            autoPlay={false}
            showThumbs={false}
            infiniteLoop={true}
            showArrows={true}
          >
            {!data ? (
              <LoadingSeaner />
            ) : (
              data.map((x) => (
                <div>
                  <img
                    style={{ maxHeight: "90vh", minHeight: "60vh" }}
                    src={x.image}
                    alt="slider images"
                  />
                </div>
              ))
            )}
          </Carousel>
        </div>

        <div class="header">
          <div class="ocean">
            <div class="wave"></div>
            <div class="wave"></div>
          </div>
        </div>
        <FetureBrand />

        <Services />
        <Product />
        <BackgroundOverlay
          bgImage={bgImageHome}
          height={400}
          content={true}
          title=""
        />
        <div id="contract">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <ContractForm />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default Home;
