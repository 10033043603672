import axios from "axios";
import React from "react";
import { useContext } from "react";
import { Table } from "react-bootstrap";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import Swal from "sweetalert2";
import { productContext } from "../index";
import { baseUrl } from "../../../../App";
import { Toast } from "../../Deshboard/Notification";

const CategorySetting = () => {
  const { category, setCategoryReload } = useContext(productContext);
  const leftShow = category.slice(0, category.length / 2);
  const rightShow = category.slice(category.length / 2, category.length);
  const tableData = [leftShow, rightShow];

  const handelEdit = async (id) => {
    const { value: category } = await Swal.fire({
      title: "Enter Category Updated  Name",
      input: "text",
      inputLabel: "Category Name",
      inputPlaceholder: "Enter Category Name",
    });

    if (category) {
      const data = { id, category };

      axios
        .patch(`${baseUrl}/category/update`, data, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setCategoryReload((reload) => !reload);
          Toast.fire({
            icon: "success",
            title: "Updated  successfully",
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `<p  href="">Please try again!!!!</p>`,
          });
        });
    }
  };

  const hendelDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${baseUrl}/category/delete/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((result) => {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            setCategoryReload((reload) => !reload);
          })
          .catch((err) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `<p  href="">Please try again!!!!</p>`,
            });
          });
      }
    });
  };
  return (
    <>
      <div className="row">
        {tableData.map((data, inx) => (
          <div className="col-md-6">
            <div className="card p-4 shadow my-3">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr className="table-heading-data">
                    <th className="text-center">SL</th>
                    <th className="text-center">Category Name</th>
                    <th colspan="2" className="text-center  ">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {data.map((x, index) => {
                    return (
                      <tr key={x.id}>
                        <td className="text-center">
                          {inx === 0 ? index + 1 : leftShow.length + index + 1}
                        </td>
                        <td className="text-center">{x.name}</td>
                        <td className="text-center">
                          <span
                            onClick={() => handelEdit(x.id)}
                            className="cursor-pointer"
                          >
                            <FiEdit className="text-success" />
                          </span>
                        </td>
                        <td className="text-center">
                          <span onClick={() => hendelDelete(x.id)}>
                            <RiDeleteBin6Line className="text-danger cursor-pointer" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default CategorySetting;
